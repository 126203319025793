import React from 'react'
import PropTypes from 'prop-types'
import HeroImage from '../components/HeroImage'
import Layout from '../components/layout'
import SEO from '../components/seo'
import opportunitiesHeroImage from '../components/HeroImage/images/mission-seek-faces-banner.jpg'
import missionSeek from '../images/mission-seek-logo-white.png'

const ServeOnMission = ({ location }) => {
  return (
    <Layout path={location.pathname}>
      <SEO title="Serve on Mission" />
      <HeroImage src={opportunitiesHeroImage} alt="Serve on Mission" title="Serve on Mission" />
      <div className="container" style={{ textAlign: 'center' }}>
        <a href="https://www.missionseek.com.au/" target="_blank" rel="noopener noreferrer">
          <img style={{ margin: '2px 0 4px 0' }} width="50%" alt="MissionSeek" src={missionSeek} />
        </a>
        <p style={{ paddingTop: '10px' }}>
          Connecting Australian Christians to mission opportunities
        </p>

        <figure className="video">
          <iframe
            title="Mission Opportunities | Short Term Mission Trips"
            width="560"
            height="315"
            src="https://www.youtube.com/embed/fbPyuzs7jbY"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </figure>
        <br />
        <p>
          <a href="https://www.missionseek.com.au/" target="_blank" rel="noopener noreferrer">
            Mission Seek
          </a>{' '}
          is our Australian platform for mission{' '}
          <a
            href="https://www.missionseek.com.au/opportunities/"
            target="_blank"
            rel="noopener noreferrer"
          >
            opportunities
          </a>{' '}
          and{' '}
          <a
            href="https://www.missionseek.com.au/mission-trips/"
            target="_blank"
            rel="noopener noreferrer"
          >
            short term mission trips
          </a>
          . It also contains{' '}
          <a
            href="https://www.missionseek.com.au/christian-volunteers/"
            target="_blank"
            rel="noopener noreferrer"
          >
            volunteer roles
          </a>
          , and{' '}
          <a
            href="https://www.missionseek.com.au/christian-jobs/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Christian jobs
          </a>{' '}
          in Australia. The website contains over 1000 listings from the members and associates of
          Missions Interlink. If you’re an Australian Christian looking to serve on mission then you
          should familiarise yourself this website. We recommend creating some alerts in order to
          receive email notifications of new mission opportunities. There are opportunities for
          retirees to serve, gap year students, those wanting to work from home, and plenty of
          unexpected possibilities.
        </p>
        <br />

        <a
          className="btn btn-primary"
          href="https://www.missionseek.com.au/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Search Opportunities Here!
        </a>
      </div>
    </Layout>
  )
}

export default ServeOnMission

ServeOnMission.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string
  }).isRequired
}
